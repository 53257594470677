
import React, { Component } from 'react';
import { Row, Col, Typography, Button, Form, Input, Spin, PageHeader, Layout, message, Card, Divider, Image, Tooltip } from 'antd'
import axios from 'axios';
import { IconAgabeColor, IconMoney } from '../../Widgets/Iconos'
import {AiOutlineInfoCircle} from 'react-icons/ai'
const { Content } = Layout;
const { Text } = Typography
const formItemLayout = { labelCol: { span: 12, offset: 6 }, wrapperCol: { span: 12, offset: 6 } };

/**
 *
 *
 * @export
 * @class Cotizador
 * @extends {Component}
 * @description Vista donde se listan todos los Cotizador
 */
export default class Cotizador extends Component {



    /**
     *Creates an instance of Cotizador.
     * @param {*} props
     * @memberof Cotizador
     */
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }
    formRef = React.createRef();

    componentDidMount() {
        this.props.clearSearch()
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getCotizador()
    }




    /**
     *
     *
     * @memberof Cotizador
     * @description Obtiene la informacion de la variante del producto para visualizarla 
     */
    getCotizador = () => {

        this.setState({ loading: true })
        axios.get('/plantas')
            .then(res => {
                console.log("res?.data?.data", res?.data?.data);
                this.setState({ data: res?.data?.data });
                this.formRef.current?.setFieldsValue({ ...res?.data?.data})
                // if (res.data.data != null) {
                // }

            })
            .catch(error => {
                
            })
            .finally(f => {
                this.setState({ loading: false })
            })
    }


    /**
     *
     *
     * @memberof Cotizador
     * @description metodo que se ejecuta al guardar valores del cotizador
     * @param {object} values Datos del formulario 
     */
    onSubmit = (values) => {
        this.setState({ loading: true })

        axios.post('plantas/add', {
            ...values
        })
            .then((res) => {
                message.success('Cotizacion Actualizada');
            }).catch((error) => {
                message.error('Error al actualizar')
                
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader className="admin-page-header"
                        title="Simulador"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[8, 8]}>
                            <Col xs={24} md={24}>
                                <Card className='heigth-100 width-100 card-zeus' >
                                    <Row justify="center">
                                    <Col xs={24} md={10}>
                                        <Form
                                            layout="vertical"
                                            className="width-100"
                                            ref={this.formRef}
                                            onFinish={this.onSubmit}
                                        >

                                            <Form.Item
                                                name="precio_kilo"
                                                labelCol={24}
                                                label={

                                                    "   Precio por Kilogramo"
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el precio',
                                                    },
                                                ]}
                                            >

                                                <Input

                                                    type="number"

                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                name="kgs_planta"
                                                labelCol={24}
                                                label=
                                                "Kilogramos por Planta"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese los Kg',
                                                    },
                                                ]}
                                            >
                                                <Input

                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="tipo_cambio"
                                                labelCol={24}
                                                label=
                                                "Tipo de Cambio Actual (1 USD a MXN)"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese los Kg',
                                                    },
                                                ]}
                                            >
                                                <Input

                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="limite"
                                                labelCol={24}
                                                label="Cantidad MÍNIMA de plantas para poder realizar compras"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese la cantidad mínima de plantas',
                                                    },
                                                ]}
                                            >
                                                <Input

                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="minimo"
                                                labelCol={24}
                                                label="Porcentaje minimo a precio de reventa"
                                                extra="Si el minimo debe de ser el mismo precio de la hacienda el valor debe de ser 0"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el minimo de porcentaje',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    
                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                                
                                            </Form.Item>

                                            <Form.Item
                                                name="maximo"
                                                labelCol={24}
                                                label="Porcentaje maximo a precio de reventa"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el maximo de porcentaje',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="minimo_reventa"
                                                labelCol={24}
                                                label="Minino de plantas para revender"
                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese la cantidad minima a revender',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    
                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                                
                                            </Form.Item>

                                            <Form.Item
                                                name="maximo_reventa"
                                                labelCol={24}
                                                label="Maximo de plantas para revender"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese la cantidad maxima a revender',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>



                                            <Form.Item className='text-center'>
                                                <Button htmlType="submit" type="primary" size="large">Guardar Valores</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>

                    </Content>
                </Spin>
            </>
        )
    }
}
