import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, DatePicker, Select, Empty } from 'antd';
import { SelectCliente } from "../../Widgets/Inputs/Inputs"
import SelectInversion from "../../Widgets/Inputs/SelectInversiones";

const { Title, Text } = Typography;
const { Option } = Select;
const moment = require('moment');
const axios = require('axios').default;
let Decimal = require('decimal.js')

/**
 *
 *
 * @class ModalReventa
 * @extends {Component}
 */
class ModalReventa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            inversion: {},
            inversion_original:{},
            hacienda: undefined,
            cliente: undefined,

            haciendas: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                search: undefined,
                loading: false
            },

            clientes: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: undefined,
                loading: false
            },
            tipo_cambio_inv: undefined,
            moneda: "MXN",
            min_precio: 0
        }
    }

    formRef = React.createRef();

    async componentDidMount() {
        try {
            // Obtener primero el tipo de cambio y esperar a que termine
            await this.getTipoCambio();
            axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
            if (this.props.reventa_id) {
                this.getReventa();
            } else {
                this.formRef.current?.setFieldsValue({
                    estatus: 1,
                    moneda: "MXN",
                    fecha: moment()
                });
            }
            this.getHaciendas();

            // Si hay un cliente_id, obtener cliente
            if (this.props.cliente_id) {
                this.getCliente();
            }
        } catch (error) {
            console.error("Error en componentDidMount:", error);
        }
    }


    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente', {
            params: {
                cliente_id: this.props.cliente_id,
            }
        })
            .then(response => {
                this.formRef.current?.setFieldsValue({
                    cliente_id: (response?.data?.data?._id) ? {
                        value: response?.data?.data?._id,
                        key:  response?.data?.data?._id,
                        label: response?.data?.data?.nombre + ' ' + response?.data?.data?.apellido_paterno + ' ' + response?.data?.data?.apellido_materno,
                    } : null
                })
                this.setState({ cliente_id: response?.data?.data?._id })
            })
            .catch(error => {
                console.log("error", error);
                message.error("Error al obtener la información del cliente")
            })
            .finally(() => this.setState({ loading: false }))
    }

    handleMoneda = (value) => {
        if (value === "MXN") {
            this.setState({ min_precio: this.state.hacienda?.precio })
        } else {
            this.setState({ min_precio: Decimal.div(this.state.inversion?.hacienda_id?.precio, this.state.tipo_cambio ).add(0.01).toDecimalPlaces(2).toNumber() })
        }
    }


    /**
     * @method getReventa
     * @description Obtiene la información del inversión
     */
    getReventa = async () => {
        this.setState({ loading: true });

        try {
            const response = await axios.get(`/reventa`, {
                params: { id: this.props.reventa_id }
            });

            const inversion = response.data;

            if (!inversion) {
                throw new Error("Datos de inversión no encontrados");
            }

            // Extraer datos de inversión
            const { hacienda_id, cliente_id, inversion_original_id, cantidad, precio_reventa, moneda, estatus, fecha, tipo_cambio } = inversion;

            // Calcular min_precio de manera más clara
            const precioHacienda = hacienda_id?.precio ?? 0;
            const cambio = tipo_cambio ?? this.state.tipo_cambio;
            const min_precio = moneda === "MXN"
                ? precioHacienda
                : Decimal.div(precioHacienda, cambio).add(0.01).toDecimalPlaces(2).toNumber();

            this.setState({
                inversion,
                hacienda: hacienda_id,
                cliente: cliente_id,
                inversion_original: inversion_original_id,
                min_precio
            });

            // Actualizar formulario
            this.formRef.current?.setFieldsValue({
                hacienda_id: hacienda_id ? { 
                    value: hacienda_id._id, 
                    key: hacienda_id._id, 
                    label: hacienda_id.nombre 
                } : null,
                cliente_id: cliente_id ? { 
                    value: cliente_id._id, 
                    key: cliente_id._id, 
                    label: cliente_id.nombre 
                } : null,
                inversion_original_id: inversion_original_id ? { 
                    value: inversion_original_id._id, 
                    key: inversion_original_id._id, 
                    label: inversion_original_id.folio 
                } : null,
                cantidad,
                precio_reventa,
                monto: Decimal(cantidad).mul(precio_reventa).toDecimalPlaces(2).toNumber(),
                moneda,
                estatus: estatus === 3 ? 1 : estatus,
                fecha: moment(fecha)
            });

        } catch (error) {
            console.error("Error al obtener la información de reventa:", error);
            message.error('Error al obtener la información');
        } finally {
            this.setState({ loading: false });
        }
    };


    /**
     * @method getTipoCambio
     * @description Obtiene el tipo de cambio actual
     */
    getTipoCambio = async () => {
        try {
            const res = await axios.get('/plantas');
            const tipoCambio = res.data?.data?.tipo_cambio;

            if (tipoCambio) {
                this.setState({ tipo_cambio: tipoCambio });
                this.formRef.current?.setFieldsValue({ tipo_cambio: tipoCambio });
                return tipoCambio
            }else{
                return 0
            }
        } catch (error) {
            console.error("Error al obtener el tipo de cambio:", error);
            return 0
        }
    };


    /**
    * @method getHaciendas
    * @description Obtiene un listado de haciendas
    */
    getHaciendas = ({
        page = this.state.haciendas.page,
        limit = this.state.haciendas.limit,
        search = this.state.haciendas.search
    } = this.state.haciendas) => {
        this.setState({ haciendas: { ...this.state.haciendas, page, limit, search, loading: true } })
        axios.get('/haciendas', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            let haciendas = data.data

            this.setState({
                haciendas: {
                    ...this.state.haciendas,
                    data: haciendas.data,
                    total: haciendas.total
                }
            })
        }).catch(error => {
            message.error("Error al obtener las haciendas")
        }).finally(() => {
            this.setState(status => {
                status.haciendas.loading = false
                return status
            })
        })
    }

    /**
    * @method getClientes
    * @description Obtiene un listado de clientes
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search
    } = this.state.clientes) => {
        this.setState({ clientes: { ...this.state.clientes, page, limit, search, loading: true } })
        axios.get('/clientes', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {

            this.setState({
                clientes: {
                    ...this.state.clientes,

                    ...data
                }
            })
        }).catch(error => {
            message.error("Error al obtener los clientes")
        }).finally(() => {
            this.setState(status => {
                status.clientes.loading = false
                return status
            })
        })
    }

    /**
    * @method onFinish
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Cierra la inversion
    */
    onFinish = (values) => {

        // tipo_cambio: this.state.tipo_cambio
        // if (typeof values.cliente_id !== 'string') values.cliente_id = values.cliente_id?.value
        // if (typeof values.hacienda_id !== 'string') values.hacienda_id = values.hacienda_id?.value
        // if (typeof values.hacienda_id !== 'string') values.hacienda_id = values.hacienda_id?.value

        // if (values?.cliente_id?.value)

        values.hacienda_id = values?.hacienda_id?.value || values?.hacienda_id
        values.cliente_id = values?.cliente_id?.value || values?.cliente_id
        values.inversion_original_id = values?.inversion_original_id?.value || values?.inversion_original_id
        if (this.props.reventa_id)
            values.id = this.props.reventa_id

        // console.log('v', values)
        // return ;
        if (this.props.reventa_id)
            this.updateReventa(values)
        else
            this.addReventa(values)
    }

    /**
    * @method updateReventa
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Actualiza una inversion
    */
    updateReventa = (values) => {
        console.log("values", values);
        this.setState({ loading: true })
        axios.put('/reventa', {...values, id:this.props.reventa_id}).then((success) => {
            message.success("Reventa actualizada")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error.response.data);
            if (error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error("Error al actualizar reventa")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method addReventa
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Crea una inversion
    */
    addReventa = (values) => {
        this.setState({ loading: true })
        axios.post('/revender', values).then((success) => {
            message.success("Reventa creada")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error reventa", error)
            if (error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error("Error al crear reventa")
        }).finally(() => this.setState({ loading: false }))
    }



    render() {

        const { inversion } = this.state
        const { hacienda_id, cliente_id } = inversion

        const disabled = (this.props.reventa_id !== undefined)

        return (
            <Form
                layout="vertical"
                ref={this.formRef}
                onFinish={this.onFinish}
                style={{ padding: '1em' }}
                initialValues={{
                    fecha: moment(),
                    estatus: 1
                }}
                onValuesChange={({ cantidad, precio_reventa, moneda, cliente_id, hacienda_id }, allValues) => {
                    // Si el precio o la cantidad cambian, se hace el calculo del monto

                    if (cliente_id)
                        this.setState({ cliente_id: cliente_id?.value || cliente_id })


                    if (hacienda_id)
                        this.setState({ hacienda_id: hacienda_id?.value || hacienda_id })


                    if (cantidad || moneda || precio_reventa) {

                        const { hacienda } = this.state

                        precio_reventa = precio_reventa || (
                            (allValues.moneda == "USD") ?
                                Decimal(hacienda.precio).div(this.state.tipo_cambio || 0).toDecimalPlaces(2).toNumber() :
                                hacienda.precio
                        )
                        cantidad = allValues.cantidad
                        let monto = Decimal(precio_reventa || 0).mul(Decimal(cantidad || 0)).toDecimalPlaces(2).toNumber()
                        this.formRef.current?.setFieldsValue({ precio_reventa, monto, moneda: allValues.moneda })
                    }

                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[24, 24]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una cliente"
                                }]}
                            >
                                <SelectCliente
                                    disabled={this.props.reventa_id || this.props.cliente_id}
                                    onSelect={() => {
                                        console.log('SELECT');
                                        this.formRef.current.setFieldsValue({ inversion_original_id: null })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Hacienda"
                                name="hacienda_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una hacienda"
                                }]}
                            >
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(search) => this.getHaciendas({ search })}
                                    notFoundContent={<Empty
                                        description="No se encontró ninguna hacienda"
                                    />}
                                    disabled={this.props.reventa_id}
                                    labelInValue
                                    onSelect={(e) => {
                                        this.formRef.current.setFieldsValue({ inversion_original_id: null})
                                    }}
                                >
                                    {
                                        this.state.haciendas.data.map(({ _id, nombre }) => {
                                            return <Option key={_id} value={_id}>{nombre}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Inversión"
                                name="inversion_original_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una inversión"
                                }]}
                            >
                                <SelectInversion
                                    disabled={this.props.reventa_id}
                                    cliente_id={this.props.cliente_id ?? this.state.cliente_id}
                                    hacienda_id={this.state.hacienda_id}
                                    params={{ estatus: 2 }}
                                    // console.log('argsargsargs',data)
                                    onSelect={(value, data) => {
                                        this.setState({  hacienda: data.hacienda_id, inversion_original:data, min_precio: data.hacienda_id?.precio })
                                        this.formRef.current.setFieldsValue({
                                            hacienda_id: {
                                                key: data.hacienda_id?._id,
                                                value: data.hacienda_id?._id,
                                                label: data.hacienda_id?.nombre,
                                            },
                                            cliente_id: {
                                                key: data.cliente_id?._id,
                                                value: data.cliente_id?._id,
                                                label: `${data.cliente_id?.nombre} ${data.cliente_id?.apellido_paterno} ${data.cliente_id?.apellido_materno} `
                                            },
                                            cantidad: data.cantidad,
                                            precio_reventa: data.hacienda_id?.precio,
                                            monto: Decimal(data.hacienda_id?.precio || 0).mul(Decimal(data.cantidad || 0)).toDecimalPlaces(2).toNumber(),
                                            moneda: "MXN",
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        
                        
                        
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Cantidad de Plantas a Vender"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Ingrese las plantas a comprar"
                                },
                                ({ getFieldValue }) => ({
                                    validator: (_, value) => {

                                        // if (value > this.state.hacienda.disponible)
                                        //     return Promise.reject(new Error(`La hacienda solo tiene ${this.state.hacienda.disponible} plantas`))
                                        if (value > this.state.inversion_original.cantidad)
                                            return Promise.reject(new Error(`No puedes vender mas plantas de las que compraste`))

                                        return Promise.resolve()
                                    }
                                })
                                ]}
                            >
                                <InputNumber
                                    disabled={!this.state.hacienda}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    min={1}
                                    precision={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label={<Text>Precio Planta</Text>}
                                name="precio_reventa"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el precio por planta"
                                },
                                ({ getFieldValue }) => ({
                                    validator: (_, value) => {

                                        // if (value > this.state.hacienda.disponible)
                                        //     return Promise.reject(new Error(`La hacienda solo tiene ${this.state.hacienda.disponible} plantas`))
                                        if (value < this.state.min_precio)
                                            return Promise.reject(new Error(`No puedes establecer el precio mas bajo que en la hacienda`))

                                        return Promise.resolve()
                                    }
                                })]}
                            >
                                <InputNumber
                                    // disabled={!this.state.hacienda}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    min={0.01}
                                    precision={2}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={this.props.reventa_id ? 8 : 12} >
                            <Form.Item
                                label="Monto"
                                name="monto"
                                
                            >
                                <InputNumber
                                    disabled={true}
                                    
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    precision={2}
                                />
                            </Form.Item>
                        </Col>
                        {
                            this.props.reventa_id && (
                                <Col xs={24} md={8} >
                                    <Form.Item
                                        label="Tipo Cambio"
                                        name="tipo_cambio"
                                    >
                                        <InputNumber
                                            disabled
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} md={this.props.reventa_id ? 8 : 12} >
                            <Form.Item
                                label="Moneda"
                                name="moneda"
                                
                            >
                                <Select
                                onSelect={(value)=>{
                                    this.handleMoneda(value)
                                }}
                                >
                                    <Option value={"MXN"}>MXN</Option>
                                    <Option value={"USD"}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                            >
                                <Select>
                                    <Option value={0}>Cancelada</Option>
                                    <Option value={1}>En venta</Option>
                                    <Option value={2}>Vendida</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="center">
                            <Form.Item className="m-0">
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"

    >
        <div className="center">
            <Title level={3}>Reventa</Title>
        </div>
        <ModalReventa {...props} />
    </Modal>

}