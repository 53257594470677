import React from "react";
import { PageHeader, Row, Col, Button, Card, List, Spin, Space, Typography, message, Popconfirm, Tag, Tooltip, Modal, Collapse, Popover } from 'antd';
import axios from 'axios';
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { User } from '../../../Hooks/Logged';
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, FilterOutlined, QuestionCircleOutlined, QuestionOutlined, SwapOutlined } from "@ant-design/icons"
import Decimal from "decimal.js";

import Sort from "../../Widgets/Sort";
import { renderEstatusInversion as estatusInversion } from "../../Utils";
import useSearchParams from '../../../Hooks/SearchParams';
import ModalReventa from "../Reventas/ModalReventa";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { color } from "echarts";

const moment = require('moment')
const { Text } = Typography
const { Panel } = Collapse

// Función para hacer un parseo seguro de JSON
const safeParse = (value, defaultValue) => {
    try {
        return value ? JSON.parse(value) : defaultValue;
    } catch (error) {
        console.error("Error al parsear JSON:", error);
        return defaultValue;
    }
};

/**
 *
 *
 * @export
 * @class reventas
 * @extends {React.Component}
 */
class Reventas extends React.Component {

    static contextType = User

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            reventas: {
                data: [],

                page: page ?? 1,
                limit: limit ?? 10,

                total: 0,
                pages: 0,

                search: search ?? undefined,

                filters: safeParse(filters, []),
                sort: safeParse(sort, {}),
            },

            cliente_id: null,
            hacienda_id: null,
            tempCantidades: {
                pendientes: 0,
                vendidas: 0,
                total: 0,
            },
            loadingCant: false
        }
    }

    drawerFiltros = React.createRef()

    async componentDidMount() {
        await this.getTipoCambio()
        this.getReventas();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getReventas({ search: this.props.search })
        }
    }

    /**
    * @method getreventas
    * @description Obtiene las reventas realizadas
    */
    getReventas = ({

        page = this.state.reventas.page,
        limit = this.state.reventas.limit,

        search = this.state.search,
        filters = this.state.reventas.filters,
        sort = this.state.reventas.sort,

    } = this.state.reventas) => {

        const params = { page, limit, }
        if (search) params.search = search
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        this.props.setParams(params)

        this.setState({ reventas: { ...this.state.reventas, page, limit, search, filters, sort }, loading: true })
        axios.get('/reventas', {
            params: {
                page,
                limit,
                search,

                filters,
                sort
            }
        }).then(({ data }) => {

            this.setState({
                reventas: {
                    ...this.state.reventas,
                    ...data
                }
            })

        }).catch(error => {
            message.error("No fue posible obtener las reventas")
        }).finally(() => this.setState({ loading: false }))
    }

    getCantidades = (reventa_id) => {
        this.setState({ loadingCant: true })
        axios.get('/reventa/cantidades', {
            params: {
                reventa_id
            }
        })
            .then(({ data }) => {
                this.setState({tempCantidades: data.data})
            })
            .catch(err => {

                message.error('No se pudieron cargar las cantidades.')
            })
            .finally(() => this.setState({ loadingCant: false }))

    }

    renderEstatusInversion = ({ estatus = 0 }) => {

        let steps = {
            0: <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
            1: <Tag className="tag-inversion-pendiente">EN VENTA</Tag>,
            2: <Tag className="tag-inversion-pagada">VENDIDA</Tag>,
            3: <Tag className="tag-inversion-cancelada">REQUIERE MODIFICAR</Tag>,
        }

        return estatus != undefined ? steps[estatus] : 'N/A'

    }

    renderTipo = ({ tipo = 0 }) => {

        let steps = {
            0: <Tag className="tag-inversion-cancelada">N/A</Tag>,
            1: <Tag className="tag-inversion-pendiente">VENTA RAPIDA</Tag>,
            2: <Tag className="tag-inversion-pagada">VENTA NORMAL</Tag>,

        }

        return tipo != undefined ? steps[tipo] : 'N/A'

    }

    /**
 *
 *
 * @param {*} event
 * @param {*} filtro
 * @memberof Clientes
 * @description Renderizamos los filtros que estén en el arreglo.
 */
    renderFiltros = (
        { filters } = this.state.reventas
    ) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.filter?.nombre || filtro?.filter?.label || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }


    /**
 *
 *
 * @param {*} key
 * @memberof Transacciones
 */
    setSort = (key) => {
        let value;
        switch (this.state.reventas.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getReventas({
            sort: {
                ...this.state.reventas.sort,
                [key]: value
            }
        })
    }

     /**
     * @method getTipoCambio
     * @description Obtiene el tipo de cambio actual
     */
    getTipoCambio = async () => {
        try {
            const res = await axios.get('/plantas');
            const tipoCambio = res.data?.data?.tipo_cambio;

            if (tipoCambio) {
                this.setState({ tipo_cambio: tipoCambio });
                return tipoCambio
            }else{
                return 0
            }
        } catch (error) {
            console.error("Error al obtener el tipo de cambio:", error);
            return 0
        }
    };


    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ reventas: { ...this.state.reventas, filters } }, () => this.getReventas())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    render() {

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="admin-page-header"
                    title="Reventas"
                    extra={<Space>
                        {/* <Button icon={<FilterOutlined style={{ color: "currentcolor" }} onClick={() => this.setState({ visibleFilters: true })} />} /> */}
                    </Space>}

                >
                    {this.renderFiltros()}
                </PageHeader>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    dataSource={this.state.reventas.data}
                    locale={{ emptyText: "Sin Reventas" }}
                    pagination={{
                        current: this.state.reventas.page,
                        pageSize: this.state.reventas.limit,
                        total: this.state.reventas.total,
                        pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page, limit) => this.getReventas({ page, limit })
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">
                        <Col xs={3} className="center">
                            <Sort
                                sort={this.state.reventas?.sort && this.state.reventas?.sort['folio']} onClick={() => this.setSort("nombre")} >
                                Folio
                            </Sort>
                        </Col>
                        <Col xs={3} className="center">
                            <Sort
                                sort={this.state.reventas?.sort && this.state.reventas?.sort['folio']} onClick={() => this.setSort("nombre")} >
                                Cliente
                            </Sort>
                        </Col>
                        <Col xs={2} className="center">
                            <Sort
                                sort={this.state.reventas?.sort && this.state.reventas?.sort['folio']} onClick={() => this.setSort("nombre")} >
                                Inversión
                            </Sort>
                        </Col>
                        <Col xs={3} md={2} className="center">
                            <Sort
                                sort={this.state.reventas?.sort && this.state.reventas?.sort['cantidad']} onClick={() => this.setSort("nombre")} >
                                Cantidad
                            </Sort>
                        </Col>
                        <Col xs={3} className="center">
                            Estatus
                        </Col>
                        <Col xs={2} className="center">
                            <Sort
                                sort={this.state.reventas?.sort && this.state.reventas?.sort['createdAt']} onClick={() => this.setSort("createdAt")} >
                                Fecha
                            </Sort>
                        </Col>
                        <Col className="center" xs={3}>
                            Precio por Planta
                        </Col>
                        <Col xs={4} className="center">Hacienda</Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Collapse bordered={false} className=" w-100 " ghost={true}>
                                <Panel className="" showArrow={false} header={<Card className="card-list" >
                                    <Row className="width-100 " gutter={[12, 12]}>
                                        <Col xs={3} className="center">
                                            <Text ellipsis>
                                                {item.folio}
                                            </Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text>{item.cliente_id?.nombre} {item.cliente_id?.apellido_materno} {item.cliente_id?.apellido_paterno}</Text>
                                        </Col>
                                        <Col xs={2} className="center">

                                            <Text ellipsis>
                                                <Link to={item.inversion_original_id?.estatus === 0 ? '#' : `/admin/crm/clientes/detalle/inversion/${item.inversion_original_id?._id}`} style={{ color: '#000000' }}>
                                                    {item.inversion_original_id?.folio}
                                                </Link>
                                            </Text>

                                        </Col>
                                        <Col xs={3} md={2} className="center">
                                            <Popover
                                                onOpenChange={(e) => e ? this.getCantidades(item._id) : this.setState({ tempCantidades: { total: 0, vendidas: 0, pendientes: 0 } })}
                                                overlayStyle={{
                                                    boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                                                }}
                                                content={
                                                    <Spin spinning={this.state.loadingCant}>
                                                        {this.state.tempCantidades.total || 0} plantas en total. <br />
                                                        {this.state.tempCantidades.vendidas || 0} plantas vendidas. <br />
                                                        {this.state.tempCantidades.pendientes || 0} plantas pendientes <br />
                                                    </Spin>
                                                }>
                                                {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
                                            </Popover>
                                        </Col>
                                        <Col xs={3} className="center">
                                            {this.renderEstatusInversion(item)}
                                        </Col>
                                        <Col xs={2} className="center">
                                            <Text>  {moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                        </Col>

                                        <Col className="center" xs={3}>
                                            $ {item.precio_reventa?.toMoney(true) ?? "0.00"} {item.moneda} / $ {item.moneda === "MXN" ? Decimal.div(item.precio_reventa, this.state.tipo_cambio).toDecimalPlaces(2).toNumber().toMoney(true) : Decimal.mul(item.precio_reventa, this.state.tipo_cambio).toDecimalPlaces(2).toNumber().toMoney(true)} {item.moneda === "USD" ? "MXN" : "USD"}
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Space>
                                                <CustomAvatar
                                                    image={item.hacienda_id?.imagenes?.length > 0 ? item.hacienda_id?.imagenes[0]?.filename : null}
                                                    name={item.hacienda_id?.nombre}
                                                />
                                                <Text>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={1} style={{ justifyContent: "end" }}>
                                            <Space>
                                                <Tooltip title="Editar Reventa">
                                                    <Button
                                                        type="primary"
                                                        icon={<EditOutlined />}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.setState({
                                                                reventa_id: item._id,
                                                                modalReventasVisible: true
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>

                                                <Tooltip title="Editar Reventa">
                                                    <Button
                                                        danger
                                                        disabled={item.cantidad_vendida > 0 ? true : false}
                                                        type="primary"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: "¿Deseas eliminar la reventa",
                                                                onOk: () => {
                                                                    this.setState({ loading: true })
                                                                    return axios.delete('/reventa', {
                                                                        params: {
                                                                            id: item._id
                                                                        }
                                                                    })
                                                                        .then(e => {
                                                                            this.getReventas()
                                                                        })
                                                                        .catch(e => {
                                                                            console.log('e', e);
                                                                            message.error("No se puede eliminar")
                                                                        })
                                                                        .finally(() => this.setState({ loading: false }))
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list mb-3"
                                        itemLayout="horizontal"
                                        dataSource={item.inversiones_destino_id}
                                        locale={{ emptyText: "Sin Inversiones Destino" }}
                                        header={<Row className=" width-100 pl-1 pr-1 ">
                                            <Col xs={6} className="center">

                                                Folio

                                            </Col>
                                            <Col xs={5} className="center">
                                                Monto
                                            </Col>
                                            <Col xs={2} className="center">
                                                Cantidad
                                            </Col>
                                            <Col xs={3} className="center">
                                                Estatus
                                            </Col>
                                            <Col xs={4} className="center">
                                                Fecha
                                            </Col>

                                            <Col className="center" xs={3}>
                                                Precio por Planta
                                            </Col>
                                        </Row>
                                        }
                                        renderItem={childItem => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list" >
                                                    <Row className="width-100 " gutter={[0, 12]}>
                                                        <Col xs={6} className="center">
                                                            {childItem.folio}
                                                        </Col>
                                                        <Col xs={5} className="center">
                                                            $ {childItem.monto?.toMoney(true) ?? "0.00"} {childItem.moneda}
                                                        </Col>
                                                        <Col xs={2} className="center">

                                                            <Text>{childItem.cantidad} planta{item.cantidad > 1 && 's'}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center">
                                                            {estatusInversion(childItem)}
                                                        </Col>
                                                        <Col xs={4} className="center">
                                                            <Text>  {moment(childItem.fecha).format('DD-MM-YYYY')}</Text>
                                                        </Col>

                                                        <Col className="center" xs={3}>
                                                            $ {childItem.precio_planta?.toMoney(true) ?? "0.00"} {childItem.moneda}
                                                        </Col>

                                                        <Col xs={1} style={{ justifyContent: "end" }}>
                                                            <Space>
                                                                <Tooltip title="Ver Inversion">
                                                                    <Link to={`/admin/crm/clientes/detalle/inversion/${childItem._id}`}>
                                                                        <Button
                                                                            type="primary"
                                                                            icon={<EyeOutlined />}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>


                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />

                                </Panel>
                            </Collapse>

                        </List.Item>
                    )}
                />
                <FloatingButton
                    title="Nuevo Registro"
                    onClick={() => this.setState({
                        modalReventasVisible: true,
                    })}
                />
                <ModalReventa
                    visible={this.state.modalReventasVisible}
                    onClose={(flag) => {
                        this.setState({ modalReventasVisible: false, reventa_id: null })
                        if (flag === true) {
                            this.getReventas()
                            this.props.getCliente()
                        }
                    }}
                    reventa_id={this.state.reventa_id}
                />
            </Spin>
        )
    }
}

export default function (props) {

    const navigate = useNavigate()

    const [params, setParams] = useSearchParams();



    return <Reventas {...props} navigate={navigate} setParams={setParams} params={params} />
}