import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Button, Popconfirm, PageHeader, Layout, message, Space, Form, Typography, Avatar, Input, Empty, Spin } from 'antd';
import axios from 'axios';
import MessagesList from './MessageList';
import Logged from '../../../Hooks/Logged';
import Socket from '../../../Hooks/Socket';
import '../../../Styles/Modules/Admin/soporte.scss';

const { Content } = Layout;
const { Text, Title } = Typography;

const Soporte = ({ socket, user }) => {

    const [loading, setLoading] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [conversacion, setConversacion] = useState({ data: [] });
    const [selectedCliente, setSelectedCliente] = useState(null);
    
    const formReply = useRef(null);

    useEffect(() => {
        if (!socket) return;

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        getClientes();

        socket.on('new_message', handleNewMessage);
        socket.on('refresh_list', getClientes);
        socket.on('sucessful', handleMessagesLoad);

        return () => {
            if (selectedCliente) {
                socket.emit('/admin/cliente/leave', selectedCliente.id);
            }
            socket.off('new_message', handleNewMessage);
            socket.off('refresh_list', getClientes);
            socket.off('sucessful', handleMessagesLoad);
        };

    }, [selectedCliente, socket]);

    const getClientes = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('/clientes', { params: { soporte: true, limit: 500 } });
            setClientes(data.data);
        } catch (error) {
            message.error('Error al obtener la lista de clientes');
        } finally {
            setLoading(false);
        }
    };

    const handleMessagesLoad = (res) => {
        setConversacion((prev) => ({
            data: res.page === 1 ? res.data : [...res.data, ...prev.data],
            page: res.page,
            limit: res.limit,
            total: res.total,
        }));
        setLoading(false);
    };

    const handleNewMessage = (message) => {
        setConversacion((prev) => ({ ...prev, data: [...prev.data, message] }));
    };

    const selectCliente = (cliente) => {
        if (selectedCliente === null || selectedCliente?._id !== cliente._id) {
            setLoading(true);
            if (selectedCliente) {
                socket.emit('/admin/cliente/leave', selectedCliente._id);
            }
            setSelectedCliente(cliente);
            setConversacion({ data: [] });

            socket.emit('/admin/cliente/join', cliente._id);
        }
    };


    const submitMessage = (values) => {
        if (!selectedCliente) {
            return message.error('Seleccione un cliente para enviar un mensaje');
        }
        formReply.current.resetFields();
        socket.emit('/admin/cliente/message/add', {
            id: selectedCliente.id,
            entrada: values.content,
            usuario: user._id,
            cliente_id: selectedCliente._id
        });
    };

    const endConversation = () => {
        if (selectedCliente) {
            socket.emit('/admin/cliente/end', selectedCliente._id);
            setSelectedCliente(null);
            setConversacion({ data: [] });
        }
    };

    return (
        <>
            <PageHeader className="admin-page-header" title="Soporte" />
            <Content className="admin-content content-bg pd-1">
                <Row gutter={[16, 0]}>
                    {/* Lista de clientes */}
                    <Col xs={8} md={7}>
                        <Row>
                            <Col span={24}>
                                <Input.Search placeholder="Buscar Cliente" onChange={(e) => getClientes({ search: e.target.value })} />
                            </Col>
                            <Col span={24} className="client-list mt-1">
                                {clientes.length > 0 ? (
                                    clientes.map((cliente) => (
                                        <Card key={cliente?._id} size="small" onClick={() => selectCliente(cliente)} className={`mb-1 hover ${cliente?.ultimoMensaje?.leido === false ? "no-leidok" : ""}`}>
                                            <Row>
                                                <Col xs={18} md={20}>
                                                    <Space direction="vertical" size={2}>
                                                        <Text strong>{cliente?.nombre} {cliente?.apellido_paterno}</Text>
                                                        <Text ellipsis>{cliente?.email}</Text>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))
                                ) : (
                                    <Empty description="No hay clientes disponibles" className="mt-1"/>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    {/* Conversación */}
                    <Col xs={16} md={17} className="conversation-container">
                        <Card title={`Chat con: ${selectedCliente ? `${selectedCliente?.nombre} ${selectedCliente?.apellido_paterno}` : 'Seleccione un cliente'}`}>
                            {selectedCliente ? (
                                <Spin spinning={loading}>
                                    <MessagesList data={conversacion.data} />
                                    <Form layout="inline" ref={formReply} onFinish={submitMessage} className="message-form" autoComplete="off">
                                        <Form.Item name="content" style={{ flex: 1 }}>
                                            <Input placeholder="Escribe tu mensaje aquí..." />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">Enviar</Button>
                                        </Form.Item>
                                    </Form>
                                    <Button danger onClick={endConversation} style={{ marginTop: 10 }}>Terminar Conversación</Button>
                                </Spin>
                            ) : (
                                <div style={{height: "480px"}}>
                                    
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default function SoporteWrapper(props) {
    const user = useContext(Logged);
    const socket = useContext(Socket);

    return <Soporte {...props} user={user} socket={socket} />;
}
