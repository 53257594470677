import React, { Component } from "react";
import { FilePdfOutlined, SwapOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Tooltip, Typography, Card, message, Statistic, Popconfirm, Popover, Tag } from "antd";

//componentes
import { renderEstatusInversion } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton";
import { IconBtnDelete, IconBtnEdit, IconEye, IconMail, IconMailOpen } from '../../../Widgets/Iconos';

//Modales
import ModalTransaccionDetalle from "../../Transacciones/ModalTransaccionDetalle"
import ModalTransaccion from "../../Transacciones/ModalTransaccion"

import usePermissions from "../../../../Hooks/usePermissions"

const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class TransaccionesInversion
 * @extends {Component}
 * @description Listado de transacciones relacionadas a una inversion
 */
class TransaccionesInversion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones()
    }

    /**
    * @memberof TransaccionesInversion
    * @description Obtiene las transacciones relacionadas a una inversion
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
    } = this.state.transacciones) => {
        this.setState({ loading: true })
        axios.get('/transacciones', {
            params: {
                page,
                limit,
                inversion_id: this.props.inversion_id,
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.transacciones.total = data.data.itemCount
                    state.transacciones.data = data.data.itemsList
                    state.transacciones.pages = data.data.pageCount
                    state.transacciones.loading = false
                    return state;
                })
            })
            .catch(err => {

                message.error('No se pudieron cargar las transacciones.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
     *
     *
     * @memberof Transacciones
     *
     * @method sendPDF
     * @description Enviamos el comprobante de pago por email
     */
    sendPDF = (transaccion_id) => {
        this.setState({ loading: true });
        return axios
            .get("/transaccion/comprobante/pdf", {
                params: {
                    transaccion_id: transaccion_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Comprobante Enviado");
                this.getTransacciones();
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al enviar el comprobante");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };


    /**
   *
   * @memberof Transacciones
   * 
   * @method getPDF
   * @description Generamos el PDF del comprobante de pago
   */
    getPDF = (transaccion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('transaccion_id', transaccion_id)
        window.open(urlPDF.href, '_blank');
    }

    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list "
                    size="large"
                    itemLayout="horizontal"
                    dataSource={this.state.transacciones.data}
                    locale={{ emptyText: "Sin Transacciones" }}
                    renderItem={item => (
                        <List.Item className="component-list-item" key={item._id}>
                            <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                                <Row className="width-100 " gutter={[0, 8]}>
                                    <Col className="center" xs={12} md={6} xl={5}>
                                        <Text>{moment(item.fecha).format("DD-MM-YYYY")}</Text>
                                    </Col>
                                    <Col className="center" xs={12} md={6} xl={9}>
                                        <Text>{item.concepto}</Text>
                                    </Col>
                                    <Col className="center" xs={12} md={6} xl={5}>
                                        <Text>$ {item.monto.toMoney(true)} {item.moneda}</Text>
                                    </Col>
                                    <Col className="flex-right" xs={24} md={5}>
                                        <Space direction="horizontal">

                                            {
                                                item.fecha_envio ? (
                                                    <Tooltip
                                                        title={`Enviado hace ${moment(item.fecha_envio).toNow(true)}`}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            className='ant-btn-primary-purple'
                                                            icon={<IconMailOpen />}
                                                            onClick={() => this.sendPDF(item._id)}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className='ant-btn-primary-purple'
                                                        icon={<IconMail />}
                                                        onClick={() => this.sendPDF(item._id)}
                                                    />
                                                )
                                            }

                                            <Button
                                                type="primary"
                                                className='ant-btn-primary-blue'
                                                icon={<FilePdfOutlined />}
                                                onClick={() => this.getPDF(item._id)}
                                            />

                                            <Button
                                                type="primary"
                                                className='ant-btn-primary-green'
                                                icon={<IconEye />}
                                                onClick={() => this.setState({ modal_visible_detalle: true, transaccion_id: item._id })}
                                            />

                                            <Button
                                                type="primary"
                                                className='ant-btn-primary'
                                                icon={<IconBtnEdit />}
                                                disabled={!this.props.editar}
                                                onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}
                                            />

                                            <Popconfirm
                                                disabled={!this.props.delete}
                                                placement="topRight"
                                                title="¿Deseas eliminar esta transacción?"
                                                onConfirm={() => axios.delete('/transacciones/delete', { params: { id: item._id } }).then((response) => {
                                                    message.success(response?.data?.message)
                                                    this.getTransacciones()
                                                    this.props.getInversion()
                                                })
                                                    .catch((error) => {
                                                        message.error(error?.response?.data?.message);

                                                    })
                                                }
                                                okText="Sí"
                                                cancelText="No"

                                            >
                                                <Button type="primary" danger className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />
                {this.props.create && <FloatingButton title="Nueva Transacción" onClick={() => this.setState({ modal_visible: true })} />}

                <ModalTransaccion
                    visible={this.state.modal_visible}
                    onCancel={(flag) => {
                        this.setState({ modal_visible: false, transaccion_id: undefined })
                        if (flag === true) {
                            this.getTransacciones()
                            this.props.getInversion()
                        }
                    }}
                    inversion_id={this.props.inversion_id}
                    transaccion_id={this.state.transaccion_id}
                    cliente_id={this.props?.cliente_id}
                    cliente={this.props.cliente}
                />
                <ModalTransaccionDetalle
                    visible={this.state.modal_visible_detalle}
                    onCancel={(flag) => { this.setState({ modal_visible_detalle: false, transaccion_id: undefined }) }}
                    transaccion_id={this.state.transaccion_id}

                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

    return <TransaccionesInversion
        {...props}
        {...usePermissions(null, {
            editar: ["transacciones", "edit"],
            delete: ["transacciones", "delete"],
            create: ["transacciones", "create"],
        })}
    />
}