import React, { useContext, useRef, useEffect, useState } from "react";
import { Layout, Row, Col, Button, Form, Input, message, Typography } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { BiSupport } from "react-icons/bi";

//Componentes
import MessagesList from "./MessageList";
import Socket from "../../Hooks/Socket";
import User from "../../Hooks/Logged";

//Estilos
import '../../Styles/Modules/Customer/soporte.scss';


const { Content } = Layout;
const { Title } = Typography;

/**
 *
 * @function Soporte
 * 
 * @description Componente para el chat de soporte del cliente
 */
const Soporte = ({ cliente_id, socket, user }) => {


    const [visible, setVisible] = useState(false);
    const [conversacion, setConversacion] = useState({
        page: 1,
        limit: 20,
        data: [],
    });
    const formReply = useRef(null);

    // Establecer los eventos de Socket.IO
    useEffect(() => {
        if (!socket || !cliente_id) return;

        const handleNewMessage = (data) => {
            setConversacion((prev) => ({
                ...prev,
                data: [...prev.data, data],
            }));
        };

        const handleMessagesLoad = (res) => {
            setConversacion((prev) => ({
                data: res.page === 1 ? res.data : [...res.data, ...prev.data],
                page: res.page,
                limit: res.limit,
                total: res.total,
            }));
        };

        const handleError = (errorMsg) => {
            message.error(errorMsg);
        };

        // Conexión al chat
        socket.emit('/admin/cliente/join', cliente_id);
        socket.on('new_message', handleNewMessage);
        socket.on('sucessful', handleMessagesLoad);
        socket.on('error', handleError);

        return () => {
            socket.emit('/admin/cliente/leave', cliente_id);
            socket.off('new_message', handleNewMessage);
            socket.off('sucessful', handleMessagesLoad);
            socket.off('error', handleError);
        };
    }, [socket, cliente_id]);

    const toggleVisible = () => {
        setVisible(!visible);
    };

    const handleSubmit = (values) => {
        if (!cliente_id) {
            return message.error("Hubo un error al enviar la consulta.");
        }
        if (values.content?.trim()) {
            formReply.current.resetFields();
            socket.emit('/admin/cliente/message/add', {
                cliente_id,
                entrada: values.content,
                usuario: user._id,
                cliente_id: cliente_id,
            });
        }
    };

    return (
        <>
            <Button
                type="primary"
                style={{
                    position: "fixed",
                    bottom: '0px',
                    left: '0px',
                    zIndex: 100,
                    borderTopRightRadius: '100px',
                }}
                onClick={toggleVisible}
                icon={<BiSupport style={{ color: "currentcolor", fontSize: 28, position: "relative", right: 3, top: 3 }} />}
            />

            <div className={`soporte ${visible ? 'visible' : 'oculto'}`}>
                <div className="titulo">CHAT SOPORTE</div>

                <div className="chat-container">
                    {/* Lista de mensajes */}
                    
                    <MessagesList data={conversacion.data} />
                    

                    {/* Formulario de envío */}
                    <div className="bottom-content">
                        <Form
                            layout="inline"
                            className="width-100 pd-1"
                            onFinish={handleSubmit}
                            ref={formReply}
                            autoComplete="off"
                        >
                            <Col xs={15} md={19}>
                                <Form.Item name="content">
                                    <Input
                                        placeholder="Escribe tu mensaje aquí..."
                                        className="width-100"
                                        style={{ marginTop: 6 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={5} md={5}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ marginTop: '5px' }}>
                                        Enviar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default function SoporteWrapper(props) {
    const socket = useContext(Socket);
    const user = useContext(User);

    return (
        <Soporte
            {...props}
            cliente_id={user?.cliente?._id}
            socket={socket}
            user={user}
        />
    );
}
