import React from 'react'
import { Form, Modal, Spin, Typography, Row, Col, Input, Radio, message, Empty, Select, Button } from 'antd';
import SelectCliente from '../../Widgets/Inputs/SelectCliente';
import axios from 'axios';

const { Title, Text } = Typography

class FormNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            haciendas: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                search: undefined,
                loading: false
            },
            destinatarios: 'all'
        }
    }

    componentDidMount() {
        this.getHaciendas()
    }

    formRef = React.createRef();

    /**
    * @method getHaciendas
    * @description Obtiene un listado de haciendas
    */
    getHaciendas = ({
        page = this.state.haciendas.page,
        limit = this.state.haciendas.limit,
        search = this.state.haciendas.search
    } = this.state.haciendas) => {
        this.setState({ haciendas: { ...this.state.haciendas, page, limit, search, loading: true } })
        axios.get('/haciendas', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            let haciendas = data.data

            this.setState({
                haciendas: {
                    ...this.state.haciendas,
                    data: haciendas.data,
                    total: haciendas.total
                }
            })
        }).catch(error => {
            message.error("Error al obtener las haciendas")
        }).finally(() => {
            this.setState(status => {
                status.haciendas.loading = false
                return status
            })
        })
    }

    onFinish = () => {
        axios.post('/notification/send', this.formRef.current.getFieldsValue()).then(response => {
            message.success('Notificacion Enviada')
            this.props.onClose()
        })
    }

    render() {
        return <Form
            layout="vertical"
            ref={this.formRef}
            onFinish={this.onFinish}
            style={{ padding: '1em' }}
        >
            <Spin spinning={this.state.loading}>
                <Row gutter={[24, 12]}>
                    <Col xs={24} lg={24} >
                        <Form.Item
                            label="Titulo"
                            name={'titulo'}
                            rules={[
                                { required: true, message: "Ingrese el titulo" }
                            ]} >
                            <Input placeholder="Titulo"></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24} >
                        <Form.Item
                            label="Mensaje"
                            name={'message'}
                            rules={[
                                { required: true, message: "Ingrese el mensaje" }
                            ]} >
                            <Input.TextArea placeholder="Mensaje"></Input.TextArea>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24} >
                        <Form.Item
                            label="Destinatarios"
                            name={'destinatarios'}
                            initialValue={'all'}
                            
                            rules={[
                                { required: true, message: "Elija un destinatario" }
                            ]} >
                            <Radio.Group onChange={(e) => this.setState({ destinatarios: e.target.value })}>
                                <Radio value="all">Todos los clientes</Radio>
                                <Radio value="hacienda">Clientes con inversion en una hacienda</Radio>
                                <Radio value="cliente">Cliente en especifico</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {
                        this.state.destinatarios === 'cliente' ?
                            <Col xs={24} lg={24} >
                                <Form.Item
                                    label="Cliente"
                                    name={'cliente_id'}
                                    rules={[
                                        { required: true, message: "Seleccione un cliente" }
                                    ]} >
                                    <SelectCliente />
                                </Form.Item>
                            </Col> : null
                    }
                    {
                        this.state.destinatarios === 'hacienda' ?
                            <Col xs={24} lg={24} >
                                <Form.Item
                                    label="Hacienda"
                                    name={'hacienda_id'}
                                    rules={[
                                        { required: true, message: "Seleccione una hacienda" }
                                    ]} >
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={true}
                                        filterOption={false}
                                        allowClear={true}
                                        onSearch={(search) => this.getHaciendas({ search })}
                                        notFoundContent={<Empty
                                            description="No se encontró ninguna hacienda"
                                        />}

                                    // onSelect={a => {

                                    //     console.log("this.formRef",)
                                    //     // if (this)

                                    // }}
                                    >
                                        {
                                            this.state.haciendas.data.map(({ _id, nombre }) => {
                                                return <Select.Option key={_id} value={_id}>{nombre}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> : null
                    }
                </Row>
                <Row>
                        <Col span={24} className="center">
                            <Form.Item className="m-0">
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Spin>

        </Form>
    }
}

export default function ModalNotif(props) {
    const { visible = true, onClose = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Notificaciones</Title>
        </div>
        <FormNotification {...props} />
    </Modal>

}