import React from "react";
import moment from "moment";
import { IconClock } from "../../Widgets/Iconos";
import { Col, Typography } from "antd";
require('moment-precise-range-plugin');

const { Text } = Typography;

export default class EdadCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diff: this.calculateDiff(props.fecha_creacion)
    };
  }

  // Método para calcular la diferencia precisa
  calculateDiff = (fecha) => {
    return moment.preciseDiff(moment(fecha), moment(), true);
  };

  componentDidMount() {
    // Actualiza la diferencia cada 30 segundos
    this.intervalId = setInterval(() => {
      this.setState({ diff: this.calculateDiff(this.props.fecha_creacion) });
    }, 30000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fecha_creacion !== prevProps.fecha_creacion) {
      this.setState({ diff: this.calculateDiff(this.props.fecha_creacion) });
    }
  }

  componentWillUnmount() {
    // Limpia el interval para evitar fugas de memoria
    clearInterval(this.intervalId);
  }

  render() {
    const { diff } = this.state;
    const { span = 12 } = this.props;
    
    // Construcción del texto para mostrar la edad
    let ageText = "";
    if (diff.years > 0) {
      ageText += `${diff.years} años `;
    }
    if (diff.months > 0) {
      ageText += `${diff.months} meses `;
    }
    // Siempre mostramos los días, incluso si es 0
    ageText += `${diff.days} dias`;

    return (
      <Col span={span} className="center">
        <IconClock />
        <Text className="text-cyan">Edad:</Text>&nbsp;
        <Text>{ageText}</Text>
      </Col>
    );
  }
}
