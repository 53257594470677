import React, { useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, message, Progress,Spin } from 'antd';

import Credenciales from './Registro/Credenciales'
import Personal from './Registro/Personal'
import Direccion from './Registro/Direccion'


const axios = require('axios').default;
/**
 * @class Registro
 * @description Modal que contiene los steps para buscar un credito
 */
const Registro = (props) => {

    let [current, setCurrent] = useState(typeof props.estatus === "number" ? props.estatus - 1 : 0)
    let [data, setData] = useState({})
    let [percent, setPercent] = useState(33)
    let [log, setLog] = useState('')
    let [loading, setLoading] = useState(false)
    let redirectLink = ""

    //Referencias por cada Step(Form)
    let credencialesRef = React.createRef();
    let PersonalRef = React.createRef();
    let DireccionRef = React.createRef();
    const navigate = useNavigate()

    useEffect(() => {
        setPercent(steps[current].percent)
    },[])

    /**
     * @memberof Registro
     *
     * @method prev
     * @description Regresa al step anterior
     **/
    const prev = () => {
        setCurrent(current - 1)
    }

    /**
     * @memberof Registro
     *
     * @method next
     * @description Pasa al step siguiente, obteniendo los datos del formularios para juntarlo en un objeto  
     **/
    const next = (data_info) => {
        try {

            let new_info = { ...data, ...data_info }

            setData(new_info)
            if (current === 2) {

                setLoading(true)
                onRegister()
                return;
            }
            setPercent(steps[current + 1].percent)
            setCurrent(current + 1)

        } catch (e) {
            
        }
    }


    /**
     * @memberof Registro
     *
     * @method onSubmit
     * @description manda una señal al formulario actual para que haga un submit al formulario y pasar al siquiente step
     **/
    const onSubmit = () => {
        steps[current].ref?.current?.submit()
    }

    /**
     * @memberof Registro
     *
     * @method onRegister
     * @description Registra el usuario nuevo
     * @param {object} values - Objecto que contiene los valores del formulario
     **/
    const onRegister = (values) => {

            setLoading(false)
            message.success(<div>
                ¡Registrado Exitosamente!
                <br></br>
                Inicia sesión para continuar
            </div>)
            props.changeView()
            setLoading(false)

        

    }


    /**
     * @memberof Registro
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    const renderRedirect = () => {
        if (log)
            navigate(redirectLink)
    };


    const steps = [
        {
            content: <Credenciales data={data} next={next} ref={credencialesRef} changeView={() => props.changeView()} />,
            ref: credencialesRef,
            percent: 33
        }, {
            content: <Personal data={data} next={next} ref={PersonalRef} />,
            ref: PersonalRef,
            percent: 66
        }, {
            content: <Direccion data={data} next={next} ref={DireccionRef} />,
            ref: DireccionRef,
            percent: 88
        }

    ];

    return (
        <>
            {renderRedirect()}
            <Row className="mb-2">
                <Col span={24}>
                    <Spin spinning={loading}>
                        <Progress strokeColor={{ '0%': '#2DDA93', '100%': '#4BD79E' }} percent={percent} showInfo={false} />
                        <div className="steps-content">{steps[current]?.content}</div>
                    </Spin>
                </Col>
            </Row>
            <Row>
                {current > 0 ? <Col span={12} className="flex-left">
                    <Button className="btn-step-back" onClick={() => prev()}>Anterior</Button>
                </Col> : <Col span={12}></Col>}
                {current <= 9 ? <Col span={12} className="flex-right">
                    <Button className="btn-step" onClick={() => onSubmit()}>Siguiente</Button>
                </Col> : null}
            </Row>
        </>
    )
}

export default function (props) {


    return <Registro {...props} />
}